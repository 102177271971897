import joinClassNames from "classnames";

import Tick16 from "icons/tick16.svg?react";
import Tick24 from "icons/tick24.svg?react";
import Dash16 from "icons/dash16.svg?react";
import Dash24 from "icons/dash24.svg?react";

import { Sizes } from "./duck/types";
import { useCheckboxContext } from "./duck/hooks";

import classes from "./styles/classes.module.scss";

interface Props {
  className?: string;
  classNames?: Partial<{ icon: string }>;
  size?: Sizes;
}

const ARROW_ICONS = {
  sm: Tick16,
  md: Tick24,
};

const DASH_ICONS = {
  sm: Dash16,
  md: Dash24,
};

const Tick = ({ className, classNames = {}, size = "md" }: Props) => {
  const { checked, isIndeterminate } = useCheckboxContext();
  const Icon = isIndeterminate ? DASH_ICONS[size] : ARROW_ICONS[size];

  return (
    <div
      data-type="tick"
      className={joinClassNames(
        classes.sizePresets,
        classes.wrapper,
        className,
      )}
      data-size={size}
    >
      {(checked || isIndeterminate) && <Icon className={classNames.icon} />}
    </div>
  );
};

Tick.Skeleton = ({
  size = "md",
  className,
}: Pick<Props, "size" | "className">) => (
  <div
    className={joinClassNames(classes.sizePresets, classes.skeleton, className)}
    data-type="tick"
    data-size={size}
  />
);

export default Tick;
