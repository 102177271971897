import CircledIcon from "components/icon";

import { RenderOption } from "../../duck/types";
import { Value } from "./duck/types";

import classes from "./styles/classes.module.scss";

const Option: RenderOption<Value> = ({ value, size }) => (
  <div className={classes.wrapper}>
    {value ? (
      <>
        <CircledIcon
          size={size === "sm" ? "xs" : undefined}
          src={value.icon}
          className={classes.optionIcon}
        />
        <p className={classes.text}>{value.fullName || value.name}</p>
      </>
    ) : (
      <p className={classes.text}>Choose option</p>
    )}
  </div>
);

export default Option;
